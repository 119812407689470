// Generated by Framer (6597ba8)

import { addFonts, cx, CycleVariantState, Image, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {WvnyzasGp: {hover: true}};

const cycleOrder = ["WvnyzasGp"];

const variantClassNames = {WvnyzasGp: "framer-v-u4ur82"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {"WvnyzasGp-hover": {damping: 60, delay: 0, duration: 0.75, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "tween"}, default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "WvnyzasGp", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WvnyzasGp", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const isDisplayed = () => {
if (gestureVariant === "WvnyzasGp-hover") return true
return false
}

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-lqW2U", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Image {...restProps} background={{alt: "", fit: "fill", src: new URL("assets/oAooA8mxsTXu3G7Ix2L9zbPCf8U.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/oAooA8mxsTXu3G7Ix2L9zbPCf8U.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/oAooA8mxsTXu3G7Ix2L9zbPCf8U.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/oAooA8mxsTXu3G7Ix2L9zbPCf8U.jpg", import.meta.url).href} 2048w, ${new URL("assets/oAooA8mxsTXu3G7Ix2L9zbPCf8U.jpg", import.meta.url).href} 4032w`}} className={cx("framer-u4ur82", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WvnyzasGp"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"WvnyzasGp-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1ctwn9k"} layoutDependency={layoutDependency} layoutId={"xGlsuUJ7v"} style={{backgroundColor: "rgba(38, 38, 38, 0.66)"}} transition={transition} variants={{"WvnyzasGp-hover": {backgroundColor: "rgba(38, 38, 38, 0.92)"}}}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TW9udHNlcnJhdC03MDA=", "--framer-font-family": "\"Montserrat\", serif", "--framer-font-size": "20px", "--framer-font-weight": "700", "--framer-letter-spacing": "1.1px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Privát max diszkont</motion.p></React.Fragment>} className={"framer-b8fn4w"} fonts={["GF;Montserrat-700"]} layoutDependency={layoutDependency} layoutId={"mlYa1CuyU"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} transition={transition} verticalAlignment={"top"} withExternalLayout/>)}</motion.div></Image>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-lqW2U [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lqW2U * { box-sizing: border-box; }", ".framer-lqW2U .framer-1x2oqr7 { display: block; }", ".framer-lqW2U .framer-u4ur82 { height: 200px; overflow: hidden; position: relative; width: 200px; }", ".framer-lqW2U .framer-1ctwn9k { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-lqW2U .framer-b8fn4w { flex: none; height: 52px; left: calc(50.00000000000002% - 163px / 2); position: absolute; top: calc(50.00000000000002% - 52px / 2); white-space: pre-wrap; width: 163px; word-break: break-word; word-wrap: break-word; }", ".framer-lqW2U .framer-v-u4ur82 .framer-u4ur82 { cursor: pointer; }", ".framer-lqW2U.framer-v-u4ur82.hover .framer-u4ur82 { height: 254px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"q5qxSblTY":{"layout":["fixed","fixed"]}}}
 */
const FramerleHyFKSly: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerleHyFKSly;

FramerleHyFKSly.displayName = "Frame Copy";

FramerleHyFKSly.defaultProps = {height: 200, width: 200};

addFonts(FramerleHyFKSly, [{family: "Montserrat", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/leHyFKSly:default", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf", weight: "700"}])